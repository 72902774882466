import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import {
  PERSONAL_LOAN,
  FAST_CASH,
  QUICK_LOAN,
  MICRO_LOAN,
  SALARY_ON_DEMAND,
  INSTANT_LOAN,
  FLEXI_PERSONAL_LOAN,
  PERSONAL_LOAN_SELF_EMPLOYED,
  PERSONAL_LOAN_FOR_SALARIED,
  PURCHASE_ON_EMI,
  LOAN_AGAINST_PROPERTY,
  BusinessLoan,
  PERSONAL_LOAN_UNSECURED,
  TOPUPLOAN,
  GOLD_LOAN,
  TWO_WHEELER_LOAN,
} from "./Containers/LoanProducts/constants";
import {
  BLOG_1,
  BLOG_2,
  BLOG_3,
  BLOG_4,
  BLOG_5,
  BLOG_6,
  BLOG_7,
} from "./Containers/Blog/constants";

const Loading = () => null;
const Hoc = Loadable({
  loader: () => import("./hoc/Hoc"),
  loading: Loading,
});
const Home = Loadable({
  loader: () => import("./Containers/Home/Home"),
  loading: Loading,
});
const Blogs = Loadable({
  loader: () => import("./Containers/Blogs"),
  loading: Loading,
});
const AboutUs = Loadable({
  loader: () => import("./Containers/About-Us/AboutUs"),
  loading: Loading,
});
const ContactUs = Loadable({
  loader: () => import("./Containers/Contact-Us/ContactUs"),
  loading: Loading,
});
const Blog = Loadable({
  loader: () => import("./Containers/Blog"),
  loading: Loading,
});
const Moratorium = Loadable({
  loader: () => import("./Containers/Moratorium"),
  loading: Loading,
});
const Repayment = Loadable({
  loader: () => import("./Containers/Repayment"),
  loading: Loading,
});
const RepaymentHome = Loadable({
  loader: () => import("./Containers/Repayment/RepaymentHome"),
  loading: Loading,
});
const FairPracticeCode = Loadable({
  loader: () => import("./Containers/Fair-Practice/Fair-Practice"),
  loading: Loading,
});
const GrievanceRedressalpolicy = Loadable({
  loader: () => import("./Components/GrievanceRedressalpolicyCoLender/GrievanceCoLenderIndex"),
  loading: Loading,
});
const KycPolicy = Loadable({
  loader: () => import("./Containers/KycPolicy/Kyc-Policy"),
  loading: Loading,
});

const Tnc = Loadable({
  loader: () => import("./Containers/Tnc/Tnc"),
  loading: Loading,
});

const AmlKyc = Loadable({
  loader: () => import("./Containers/Aml-Kyc"),
  loading: Loading,
});
const GrevencePolicy = Loadable({
  loader: () => import("./Containers/Grevence-Policy/GrevencePolicy"),
  loading: Loading,
});
const OmbudsmanScheme = Loadable({
  loader: () => import("./Containers/OmbudsmanScheme/OmbudsmanScheme"),
  loading: Loading,
});
const LoanProduct = Loadable({
  loader: () => import("./Containers/LoanProducts"),
  loading: Loading,
});
const Pagenotfound = Loadable({
  loader: () => import("./Containers/Page-Not-Found/PageNotFound"),
  loading: Loading,
});
const LiquidityRiskDisclosure = Loadable({
  loader: () => import("./Containers/LiquidityRiskDisclosure"),
  loading: Loading,
});
const DLAI = Loadable({
  loader: () => import("./Containers/DLAI/DLAI"),
  loading: Loading,
});
const InvestorContact = Loadable({
    loader: () => import('./Containers/InvestorContact'),
    loading: Loading
});
const FinancialResults = Loadable({
  loader: () => import("./Containers/FinancialResults"),
  loading: Loading,
});
const Documentation = Loadable({
  loader: () => import("./Components/Documentation/DocumentationIndex"),
  loading: Loading,
});
const SecurityCenter = Loadable({
  loader: () => import("./Containers/SecurityCenter"),
  loading: Loading,
});
const UnauthorizedApp = Loadable({
  loader: () => import("./Containers/UnauthorizedApp/UnauthorizedApp"),
  loading: Loading,
});
const GrievanceRedressal = Loadable({
  loader: () => import("./Containers/GrievanceRedressal/GrievanceRedressal"),
  loading: Loading,
});
const LspDla = Loadable({
  loader: () => import("./Containers/LspDla/LspDla"),
  loading: Loading,
});
const LendingPartners = Loadable({
  loader: () => import("./Components/HomePage/Partners/LendingPartner"),
  loading: Loading,
});

const MainPage = Loadable({
  loader: () => import("./Containers/Home/Login"),
  loading: Loading,
})


const Layout = () => {
  return (
    <Hoc>
      <Switch>
        {/* Url Redirects */}
        <Redirect from="/blog1" to={"/blog/" + BLOG_1} />
        <Redirect from="/blog2" to={"/blog/" + BLOG_2} />
        <Redirect from="/blog3" to={"/blog/" + BLOG_3} />
        <Redirect from="/blog4" to={"/blog/" + BLOG_4} />
        <Redirect from="/blog5" to={"/blog/" + BLOG_5} />
        <Redirect from="/blog6" to={"/blog/" + BLOG_6} />
        <Redirect from="/blog7" to={"/blog/" + BLOG_7} />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + PERSONAL_LOAN}
        />
         <Redirect
          from="/personal-loan"
          to={"/loan-products/" + FLEXI_PERSONAL_LOAN}
        />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + PERSONAL_LOAN_SELF_EMPLOYED}
        />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + PERSONAL_LOAN_FOR_SALARIED}
        />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + PURCHASE_ON_EMI}
        />
        <Redirect from="/fast-cash" to={"/loan-products/" + FAST_CASH} />
        <Redirect from="/quick-loan" to={"/loan-products/" + QUICK_LOAN} />
        <Redirect
          from="/short-term-working-capital"
          to={"/loan-products/" + MICRO_LOAN}
        />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + SALARY_ON_DEMAND}
        />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + LOAN_AGAINST_PROPERTY}
        />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + BusinessLoan}
        />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + PERSONAL_LOAN_UNSECURED}
        />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + TOPUPLOAN}
        />
        <Redirect from="/instant-loan" to={"/loan-products/" + INSTANT_LOAN} />
        <Redirect
          from="/personal-loan"
          to={"/loan-products/" + GOLD_LOAN}
        />
         <Redirect from="/personal-loan" to={"/loan-products/" + TWO_WHEELER_LOAN} />

        {/* <Redirect from="/semester-loan" to={"/loan-products/" + SEMESTER_LOAN} /> */}
        {/* <Redirect from="/blogs" to="/blogs/trending" /> */}
        {/* Routes */}
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/repay/signin" component={MainPage} />
        <Route exact path="/moratorium" component={Moratorium} />
        <Route exact path="/repayment" component={Repayment} />
        <Route exact path="/repay/:whichscreen" component={RepaymentHome} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/fair-practice-code" component={FairPracticeCode} />
        <Route exact path="/grievance-redressal-policy-co-lender" component={GrievanceRedressalpolicy} />
        <Route exact path="/privacy-policy" component={KycPolicy} />
        <Route exact path="/terms-and-conditions" component={Tnc} />
        <Route exact path="/aml-kyc" component={AmlKyc} />
        <Route
          exact
          path="/grievance-policy"
          component={GrevencePolicy}
          onUpdate={() => window.scrollTo(0, 0)}
        />
        <Route exact path="/ombudsman-scheme" component={OmbudsmanScheme} />
        <Route exact path="/blog/:id" component={Blog} />
        <Route exact path="/blogs/:type" component={Blogs} />
        <Route exact path="/loan-products/:product" component={LoanProduct} />
        <Route
          exact
          path="/liquidity-risk-disclosure"
          component={LiquidityRiskDisclosure}
        />
        <Route exact path="/dlai-code-of-conduct" component={DLAI} />
        <Route exact path="/investor-relations" component={FinancialResults} />
        <Route exact path="/documentation" component={Documentation} />
        <Route exact path="/investor-contact" component={InvestorContact} />
        <Route exact path="/security-center" component={SecurityCenter} />
        <Route exact path="/unauthorized-app" component={UnauthorizedApp} />
        <Route exact path="/grievance-redressal" component={GrievanceRedressal} />
        <Route exact path="/lsp-dla" component={LspDla} />
        <Route exact path="/lending-partner" component={LendingPartners} />
        <Route path="*" exact={true} component={Pagenotfound} />
      </Switch>
    </Hoc>
  );
};

export default Layout;
